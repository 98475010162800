import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import img1 from "../images/lz1.png";
import img2 from "../images/lz2.png";
import img3 from "../images/lz3.png";
import img4 from "../images/lz4.png";
import img5 from "../images/lz5.png";

const PortfolioPage = () => (
  <Layout>
    <SEO 
      title="Javier Suzuki | Design & Front-end Development Portfolio - Lesson Zone Educational Resources Platform"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `application`, `website`, `educational resources`, `wordpress`, `woocommerce`]}
    />
    <div className="portfolio-header">
      <h1>LessonZone Educational Resources</h1>
      <h5>Website & Web Application</h5>
      <p>Design and front-end development of website for an international educational resources platform.</p>
      <p>Initially launched in Australia & New Zealand, and later across 11 countries.</p>
      <p>Built with Wordpress, WooCommerce and hosted on Amazon Web Services (AWS).</p>
    </div>
    <div className="portfolio-footer">
      <a className="btn btn-primary" href="https://www.teaching.com.au/page/mta-au-lessonzone" target="_blank" rel="noopener noreferrer">View Project</a>
    </div>
    <div className="portfolio-content">
      <figure className="portfolio-sample">
        <figcaption className="figheading">Homepage</figcaption>
        <img src={img1} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Item Detail Page</figcaption>
        <img src={img2} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Subscription Page</figcaption>
        <img src={img3} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">About Page</figcaption>
        <img src={img4} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Free Zone Page</figcaption>
        <img src={img5} alt=""/>
      </figure>
    </div>

  </Layout>
)

export default PortfolioPage
